<script>
import Vue, { computed } from 'vue'
import Analytics from '/~/core/analytics'
import bottomSheet from '/~/core/bottom-sheet'
import DrawerCardTileV2 from '/~/components/drawer/components/tiles/drawer-card-tile.v2.vue'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { useScheduledPayments } from '/~/composables/scheduled-payments'
import { useStatementAccounts } from '/~/composables/statements'

export default {
  name: 'drawer-card-tile-v3',
  extends: DrawerCardTileV2,
  setup(props) {
    const { deletePaymentMethod } = usePaymentMethods()
    const { isScheduledPaymentsEnabled } = useScheduledPayments()
    const { isStatementAccountsEnabled } = useStatementAccounts()

    const removePaymentMethodText = computed(() => {
      if (
        isScheduledPaymentsEnabled.value ||
        isStatementAccountsEnabled.value
      ) {
        return `Are you sure you want to delete the selected payment method? This action cannot be undone.

          Please note that any direct debit or scheduled payments associated with this payment method will also be disabled/removed.`
      } else {
        return 'Are you sure you want to delete the selected payment method? This action cannot be undone.'
      }
    })

    function onRemoveClick() {
      bottomSheet.show('confirmation-modal-v2', {
        to: 'menu-modal',
        props: {
          icon: 'heroicons/solid/trash',
          iconSize: 64,
          title: 'Delete payment method',
          text: removePaymentMethodText.value,
          onConfirm: onDeleteConfirmationModalConfirm,
          analytics: {
            confirm: {
              pageGroup: 'Drawer',
              page: 'Payment Methods',
              component1: 'Credit card',
              label: 'Click on Confirm credit card removal',
            },
            cancel: {
              pageGroup: 'Drawer',
              page: 'Payment Methods',
              component1: 'Credit card',
              label: 'Click on Cancel credit card removal',
            },
          },
        },
      })
    }

    async function onDeleteConfirmationModalConfirm() {
      try {
        await deletePaymentMethod(props.card.id)

        Analytics.sendEvent({
          pageGroup: 'Drawer',
          page: 'Payment Methods',
          component1: 'Credit card',
          label: 'Credit card removed',
        })
      } catch (error) {
        if (!error.data?.message) {
          Vue.notify({
            type: 'error',
            text: 'Something went wrong while deleting. Please try again.',
          })
        }
      }
    }

    return {
      ...DrawerCardTileV2.setup?.(...arguments),
      onRemoveClick,
    }
  },
}
</script>

<template>
  <div class="relative mx-auto max-w-72">
    <drawer-method-tile
      :method="presentation"
      :class="[
        !isVerified && !inForm && !initializing
          ? 'cursor-pointer'
          : 'cursor-default',
      ]"
      @click="verify"
    />
    <div
      class="absolute right-0 top-0 -mr-2.5 -mt-2.5 flex items-center gap-x-[5px]"
    >
      <div
        v-if="expiryDate.isExpired"
        class="flex items-center rounded-full bg-amber-500 px-2.5 py-[5px] text-white"
      >
        <base-icon svg="v2/heroic/exclamation" :size="24" class="mr-[5px]" />
        <span class="whitespace-no-wrap font-bold uppercase">Expired</span>
      </div>
      <button
        v-else-if="!isVerified"
        type="button"
        class="flex cursor-pointer items-center rounded-full px-2.5 py-[5px] text-white"
        :style="{
          backgroundColor: '#F59E0B',
        }"
        @click="verify"
      >
        <base-loader v-if="initializing" size="sm" />
        <template v-else>
          <base-icon svg="v2/heroic/exclamation" :size="24" class="mr-[5px]" />
          <span class="whitespace-no-wrap font-bold uppercase">
            Verify account
          </span>
        </template>
      </button>
      <button
        v-analytics:click="{
          pageGroup: 'Drawer',
          page: 'Payment Methods',
          component1: 'Credit card',
          label: 'Click on remove credit card',
        }"
        type="button"
        class="flex h-10 w-10 items-center justify-center rounded-full border-4 bg-red-700 text-white disabled:bg-gray-300"
        :class="{
          'border-white': !isDarkThemeForEwallet,
        }"
        :disabled="deleting"
        :aria-label="`Card number ${card.number} remove`"
        @click="onRemoveClick()"
      >
        <base-loader v-if="deleting" size="sm" />
        <base-icon v-else svg="v2/heroic/trash" alt="remove" :size="16" />
      </button>
    </div>
  </div>
</template>
