<script setup lang="ts">
import { useAccess } from '/~/composables/access/use-access'
import router from '/~/router'
import DrawerPaymentMethods from '/~/templates/bill-payments/components/drawer/views/payment-methods/drawer-payment-methods.vue'

const { withAccessCheck, isCheckingIdentityVerification } = useAccess()

function onAdd() {
  withAccessCheck({
    callback: () => {
      router.push({ hash: '#profile-payment-methods-add' })
    },
    config: {
      actionType: 'paymentMethods',
    },
  })
}
</script>

<template>
  <drawer-payment-methods
    :back="{ hash: '#profile-home' }"
    :is-action-loading="isCheckingIdentityVerification"
    to="menu-modal"
    @add="onAdd"
  />
</template>
